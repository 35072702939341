*{
    font-family: Nunito;
}
::selection{
    background: #DAF0D1;
}
body{
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom right, #C6E7EC, #E4C2EB);
    height: 100vh;
}
.notificationBox{
    position: fixed;
    left: 50%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    max-width: 80%;
    height: auto;
    margin: 90px 0px;
    padding: 5px 0px;
    text-align: center;
    border-radius: 10px;
    background: #FF3BC1;
    z-index: 97;
    transform: translate(-50%, 0);
}
.notificationText{
    margin: 5px;
    color: #FFF;
}
.modalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
}
.modal{
    padding: 20px;
    width: 400px;
    max-width: 70%;
    height: 200px;
    color: #AAA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    outline: 5px solid #5BC860;
    border: 5px solid #DAF0D1;
    border-radius: 10px;
    background: #FFF;
    user-select: none;
}
.usernameInput{
    color: #BBB;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    padding: 5px;
    text-align: center;
    border: 5px solid #DAF0D1;
    border-radius: 5px;
    width: 200px;
    max-width: 60%;
    margin-bottom: 10px;
}
.usernameInput:focus{
    outline: 3px solid #5BC860;
}
.usernameInput::placeholder{
    color: #EEE;
}
.usernameButton{
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    background: #DAF0D1;
}
.usernameButton:active{
    opacity: 0.5;
}
.usernameIcon{
    color: #AAA;
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}
.appBar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    color: #FFF;
    background: #5BC860;
    z-index: 98;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
    user-select: none;
}
.appBarIcon{
    margin: 0px 0px 5px 20px;
    font-size: 40px;
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}
.chatBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 130px auto;
    width: 800px;
    max-width: 90%;
    height: auto;
    outline: 5px solid #5BC860;
    border: 5px solid #90DA9C;
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
    background: #DBF2D9;
}
.messageContainer{
    padding: 30px 30px 10px 30px;
    margin: 40px 0px 20px 0px;
    width: 75%;
    height: 400px;
    display: flex;
    flex-direction: column;
    border: 5px solid #90DA9C;
    border-radius: 10px;
    background: #FEFDFB;
    overflow-y: scroll;
}
.message{
    align-self: flex-start;
    width: 300px;
    max-width: 60%;
    margin: 0px 0px 25px 0px;
    padding: 20px 25px;
    color: #888;
    border-radius: 5px;
    background: #DAF0D1;
    user-select: none;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.messageSelf{
    align-self: flex-end;
}
.messageHeader{
    font-size: 18px;
    font-weight: bold;
    margin: 0px 0px 5px 0px;
}
.messageBody{
    font-size: 16px;
    font-weight: 500;
}
.inputBox{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0px 0px 30px 0px;
    user-select: none;
}
.messageInput{
    width: 100%;
    height: 40px;
    outline: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #aaa;
    padding: 0px 10px;
    border: 5px solid #5BC860;
    border-radius: 10px;
}
.messageInput:focus{
    outline: 3px solid #90DA9C;
}
.messageInput::placeholder{
    color: #DDD;
}
.sendButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 50px;
    margin: 0px 0px 0px 5px;
    border: 5px solid #90DA9C;
    background: #5BC860;
    border-radius: 10px;
}
.sendButton:active{
    filter: brightness(1.2);
}
.sendButtonIcon{
    font-size: 28px;
    color: #fff;
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
}